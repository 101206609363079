import React, { useRef } from 'react';
import cn from 'classnames';
import { useBiggestHeightFromListOfRefs } from '../../../../../../hooks/useBiggestHeightFromListOfRefs';
import { Card } from '../Card';
import { Image } from '../Image';
import { Title } from '../Title';
import { Text } from '../Text';
import { Link } from '../Link';
import { CardBody } from '../CardBody';

export const Row = ({
  list,
  count,
  alignStyle,
  alignColumns,
  dStyles,
  vStyles,
}) => {
  const {
    elements: {
      card: { elements: { title: { settings: { top } = {} } = {} } = {} } = {},
    } = {},
  } = vStyles;

  const titleRef = useRef([]);
  const titleMinHeight = useBiggestHeightFromListOfRefs(titleRef);

  const textRef = useRef([]);
  const textMinHeight = useBiggestHeightFromListOfRefs(textRef);

  return (
    <div
      className={cn('row', {
        'center-sm': alignColumns === 'CENTRE',
        'end-sm': alignColumns === 'RIGHT',
      })}
    >
      {list.map(
        (
          {
            title,
            body,
            alt,
            link: ctaConfig = {},
            displayCtaLinkOnImage,
            displayCtaButton,
            image = {},
            titleSize = 'H2',
            imagePosition,
            imageSize,
          },
          index,
        ) => (
          <div key={index} className={`col-xs-12 col-sm-${12 / count}`}>
            <Card dStyles={dStyles} vStyles={vStyles}>
              {top && title && (
                <Title
                  ref={(el) => {
                    titleRef.current[index] = el;
                  }}
                  content={title}
                  alignStyle={alignStyle}
                  dStyles={dStyles}
                  vStyles={vStyles}
                  style={{ minHeight: titleMinHeight }}
                  as={titleSize?.toLowerCase()}
                />
              )}
              {image.path && (
                <Image
                  imageConfig={image}
                  imageSize={imageSize}
                  imagePosition={imagePosition}
                  alt={alt}
                  ctaConfig={displayCtaLinkOnImage ? ctaConfig : {}}
                  dStyles={dStyles}
                  vStyles={vStyles}
                />
              )}
              {((!top && title) || body || ctaConfig.shown) && (
                <CardBody dStyles={dStyles} vStyles={vStyles}>
                  {!top && title && (
                    <Title
                      ref={(el) => {
                        titleRef.current[index] = el;
                      }}
                      content={title}
                      alignStyle={alignStyle}
                      dStyles={dStyles}
                      vStyles={vStyles}
                      style={{ minHeight: titleMinHeight }}
                      as={titleSize}
                    />
                  )}
                  {body && (
                    <Text
                      ref={(el) => {
                        textRef.current[index] = el;
                      }}
                      content={body}
                      alignStyle={alignStyle}
                      dStyles={dStyles}
                      vStyles={vStyles}
                      style={{ minHeight: textMinHeight }}
                    />
                  )}
                  {ctaConfig.shown && displayCtaButton && (
                    <Link
                      ctaConfig={ctaConfig}
                      alignStyle={alignStyle}
                      dStyles={dStyles}
                      vStyles={vStyles}
                    />
                  )}
                </CardBody>
              )}
            </Card>
          </div>
        ),
      )}
    </div>
  );
};
