import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { splitListIntoChunks } from '../../../../helpers';
import {
  CallToActionConfigProps,
  ImageTagConfigProps,
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
} from '../../../sharedComponents';
import { AlignWrapper } from '../../index.style';
import { Row, Underline } from '../../innerComponents';

export const DefaultDisplayStyle = (props) => {
  const {
    section: {
      title: heading,
      titleSize,
      subtitle: paragraph,
      subsections = [],
      link: ctaConfig = {},
      textAlignment: alignStyle,
      columnLayoutAlignment,
    },
    count,
    dStyles,
    vStyles,
  } = props;

  const {
    elements: {
      callToAction: { settings: { mode: callToActionMode } = {} } = {},
      underline: { settings: { topLineOnly } = {} } = {},
    } = {},
  } = vStyles;
  const {
    settings: {
      columnHeading: { md },
    },
  } = dStyles;

  return (
    <div className="container">
      <div className="row">
        <div className={`col-xs-12 col-md-${md}`}>
          {heading && (
            <AlignWrapper
              elementName="heading"
              alignStyle={alignStyle}
              dStyles={dStyles}
              vStyles={vStyles}
            >
              <StyledHeading
                content={heading}
                as={titleSize}
                displayStyles={dStyles}
                visualStyles={vStyles}
              />
            </AlignWrapper>
          )}
          {paragraph && (
            <AlignWrapper
              elementName="paragraph"
              alignStyle={alignStyle}
              dStyles={dStyles}
              vStyles={vStyles}
            >
              <StyledParagraph
                alignStyle={alignStyle}
                content={paragraph}
                displayStyles={dStyles}
                visualStyles={vStyles}
              />
            </AlignWrapper>
          )}
        </div>
      </div>
      {splitListIntoChunks(subsections, count).map((list, index) => (
        <Fragment key={index}>
          <div className="row">
            <div className="col-xs-12">
              <Underline
                index={index}
                topLineOnly={topLineOnly}
                dStyles={dStyles}
                vStyles={vStyles}
              />
            </div>
          </div>
          <Row
            list={list}
            count={count}
            alignStyle={alignStyle}
            alignColumns={columnLayoutAlignment}
            dStyles={dStyles}
            vStyles={vStyles}
          />
        </Fragment>
      ))}
      {ctaConfig.shown && (
        <div className="row">
          <div className="col-xs-12">
            <StyledCallToAction
              ctaConfig={ctaConfig}
              mode={callToActionMode}
              displayStyles={dStyles}
              visualStyles={vStyles}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DefaultDisplayStyle.propTypes = {
  count: PropTypes.oneOf([1, 2, 3, 4, 6, 12]).isRequired,
  section: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: CallToActionConfigProps,
    textAlignment: PropTypes.oneOf(['LEFT', 'CENTRE']).isRequired,
    subsections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        titleSize: PropTypes.string,
        body: PropTypes.string,
        url: PropTypes.string,
        urlText: PropTypes.string,
        image: ImageTagConfigProps,
        alt: PropTypes.string,
      }),
    ),
  }).isRequired,
};
