import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Wrapper = styled.h3`
  @media only screen and (max-width: 767px) {
    min-height: auto !important;
  }

  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}

  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}

  ${({ theme, vStyles, alignStyle }) =>
    renderStyledElementStyles(theme, vStyles.alignStyles[alignStyle])}
`;

export const Title = React.forwardRef(
  ({ content, alignStyle, style, dStyles, vStyles, as }, ref) => (
    <Wrapper
      ref={ref}
      alignStyle={alignStyle}
      dStyles={dStyles.elements?.card.elements?.title}
      vStyles={vStyles.elements?.card.elements?.title}
      style={style}
      as={as.toLowerCase()}
    >
      {content}
    </Wrapper>
  ),
);

Title.propTypes = {
  content: PropTypes.string,
  as: PropTypes.string,
};
