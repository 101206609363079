import React from 'react';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    height: 100%;
  }

  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}

  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
`;

export const Card = ({ children, dStyles, vStyles }) => (
  <Wrapper dStyles={dStyles.elements?.card} vStyles={vStyles.elements?.card}>
    {children}
  </Wrapper>
);
