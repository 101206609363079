import styled from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';

export const AlignWrapper = styled.div`
  ${({ theme, vStyles, elementName, alignStyle }) =>
    renderStyledElementStyles(
      theme,
      vStyles?.elements[elementName]?.alignStyles[alignStyle],
    )}
`;
