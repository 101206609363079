import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import {
  CallToActionConfigProps,
  ImageTagConfigProps,
  StyledCallToAction,
  StyledImageTag,
  StyledImageTagWrapper,
} from '../../../sharedComponents';

const objectFitConfig = {
  size: {
    FIT: 'contain',
    FILL: 'cover',
  },
  position: {
    CENTRE: 'center',
    LEFT: 'left',
    RIGHT: 'right',
  },
};

const Wrapper = styled.div`
  position: relative;
  display: block;

  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}

  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ imageSize }) => objectFitConfig.size[imageSize]};
    object-position: ${({ imagePosition }) =>
      objectFitConfig.position[imagePosition]}};
  }

  ${StyledImageTagWrapper} {
    height: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${({ theme, dStyles }) =>
    renderStyledElementStyles(theme, dStyles?.elements?.overlay)}

  ${({ theme, vStyles }) =>
    renderStyledElementStyles(theme, vStyles?.elements?.overlay)}
`;

export const Image = ({
  imageConfig,
  imageSize,
  imagePosition,
  ctaConfig,
  alt,
  dStyles,
  vStyles,
}) => {
  const innerContent = (
    <div className="embed-responsive embed-responsive-16by10">
      <div className="embed-responsive-item">
        <StyledImageTag imageConfig={imageConfig} alt={alt} />
        {ctaConfig.shown && (
          <Overlay
            dStyles={dStyles.elements?.card?.elements?.image}
            vStyles={vStyles.elements?.card?.elements?.image}
          />
        )}
      </div>
    </div>
  );

  return (
    <Wrapper
      dStyles={dStyles.elements?.card?.elements?.image}
      vStyles={vStyles.elements?.card?.elements?.image}
      imageSize={imageSize}
      imagePosition={imagePosition}
    >
      {ctaConfig.shown ? (
        <StyledCallToAction type="link" ctaConfig={ctaConfig}>
          {innerContent}
        </StyledCallToAction>
      ) : (
        <>{innerContent}</>
      )}
    </Wrapper>
  );
};

Image.propTypes = {
  imageConfig: ImageTagConfigProps,
  ctaConfig: CallToActionConfigProps,
  imageSize: PropTypes.oneOf(['FILL', 'FIT']),
  imagePosition: PropTypes.oneOf(['CENTRE', 'LEFT', 'RIGHT']),
  alt: PropTypes.string,
};
