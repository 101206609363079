import React from 'react';
import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }

  ${({ theme, dStyles = {} }) => renderStyledElementStyles(theme, dStyles)}

  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
`;

export const CardBody = ({ children, dStyles, vStyles }) => (
  <Wrapper
    dStyles={dStyles.elements?.card?.elements?.cardBody}
    vStyles={vStyles.elements?.card?.elements?.cardBody}
  >
    {children}
  </Wrapper>
);
