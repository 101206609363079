import styled, { css } from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Underline = styled.div`
  ${({ theme, dStyles }) =>
    renderStyledElementStyles(theme, dStyles.elements?.underline)}

  ${({ theme, vStyles }) =>
    renderStyledElementStyles(theme, vStyles.elements?.underline)}
  
  ${({ topLineOnly, index }) =>
    topLineOnly &&
    index !== 0 &&
    css`
      background-color: transparent;
    `}
`;
