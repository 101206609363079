import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { Paragraph } from '../../../sharedComponents';

const Wrapper = styled.div`
  @media only screen and (max-width: 767px) {
    min-height: auto !important;
  }

  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}

  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}

  ${({ theme, vStyles, alignStyle }) =>
    renderStyledElementStyles(theme, vStyles.alignStyles[alignStyle])}
`;

export const Text = React.forwardRef(
  ({ content, alignStyle, dStyles, vStyles, style }, ref) => (
    <Wrapper
      ref={ref}
      alignStyle={alignStyle}
      dStyles={dStyles.elements?.card?.elements?.text}
      vStyles={vStyles.elements?.card?.elements?.text}
      style={style}
    >
      <Paragraph content={content} alignStyle={alignStyle} />
    </Wrapper>
  ),
);

Text.propTypes = {
  content: PropTypes.string,
};
