import React from 'react';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import {
  CallToAction,
  CallToActionConfigProps,
} from '../../../sharedComponents';

const Wrapper = styled.div`
  a {
    display: inline-block;

    ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}

    ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
  }

  ${({ theme, vStyles, alignStyle }) =>
    renderStyledElementStyles(theme, vStyles.alignStyles[alignStyle])}
`;

export const Link = ({ ctaConfig, alignStyle, dStyles, vStyles }) => (
  <Wrapper
    alignStyle={alignStyle}
    dStyles={dStyles.elements?.card?.elements?.link}
    vStyles={vStyles.elements?.card?.elements?.link}
  >
    <CallToAction type="link" ctaConfig={ctaConfig} />
  </Wrapper>
);

Link.propTypes = {
  ctaConfig: CallToActionConfigProps,
};
