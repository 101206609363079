import { useEffect, useState } from 'react';
import maxBy from 'lodash/maxBy';

export function useBiggestHeightFromListOfRefs(ref) {
  const [height, setHeight] = useState();

  useEffect(() => {
    if (ref?.current?.length > 0) {
      const el = maxBy(
        ref.current.filter((refEl) => refEl),
        (refEl) => refEl.clientHeight,
      );
      setHeight(el.clientHeight);
    }
  }, [ref]);

  return height;
}
